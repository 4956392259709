// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-views-about-js": () => import("./../../../src/components/views/About.js" /* webpackChunkName: "component---src-components-views-about-js" */),
  "component---src-components-views-awards-js": () => import("./../../../src/components/views/Awards.js" /* webpackChunkName: "component---src-components-views-awards-js" */),
  "component---src-components-views-contact-js": () => import("./../../../src/components/views/Contact.js" /* webpackChunkName: "component---src-components-views-contact-js" */),
  "component---src-components-views-diary-js": () => import("./../../../src/components/views/Diary.js" /* webpackChunkName: "component---src-components-views-diary-js" */),
  "component---src-components-views-imprint-js": () => import("./../../../src/components/views/Imprint.js" /* webpackChunkName: "component---src-components-views-imprint-js" */),
  "component---src-components-views-index-js": () => import("./../../../src/components/views/Index.js" /* webpackChunkName: "component---src-components-views-index-js" */),
  "component---src-components-views-jobs-js": () => import("./../../../src/components/views/Jobs.js" /* webpackChunkName: "component---src-components-views-jobs-js" */),
  "component---src-components-views-newsletter-js": () => import("./../../../src/components/views/Newsletter.js" /* webpackChunkName: "component---src-components-views-newsletter-js" */),
  "component---src-components-views-privacy-js": () => import("./../../../src/components/views/Privacy.js" /* webpackChunkName: "component---src-components-views-privacy-js" */),
  "component---src-components-views-project-js": () => import("./../../../src/components/views/Project.js" /* webpackChunkName: "component---src-components-views-project-js" */),
  "component---src-components-views-reels-js": () => import("./../../../src/components/views/Reels.js" /* webpackChunkName: "component---src-components-views-reels-js" */),
  "component---src-components-views-service-js": () => import("./../../../src/components/views/Service.js" /* webpackChunkName: "component---src-components-views-service-js" */),
  "component---src-components-views-work-js": () => import("./../../../src/components/views/Work.js" /* webpackChunkName: "component---src-components-views-work-js" */)
}

